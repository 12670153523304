<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full p-10"
        >
          <div class="text-center text-c18 font-c4 mt-6">
            <icon
              :data="icons.success"
              height="100"
              width="100"
              class="cursor-pointer"
              original
            />

            <div class="text-c20 font-c8 text-red titre">
              Attention
            </div>
            <div
              class="pl-14 pr-14 text-c16 font-c4 mt-4"
            >
              {{ message }}
            </div>
          </div>

          <div class="mt-12 flex justify-center">
            <div class="w-1/2">
              <buton
                label="Annuler"
                size="16px"
                radius="5px"
                background="#EAEAEF"
                color="#000"
                weight="600"
                @oga="fermer"
              />
            </div>

            <div class="w-1/2 ml-6">
              <buton
                label="Désactiver"
                size="16px"
                weight="600"
                radius="5px"
                background="red"
                @oga="$emit('oga', true)"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import success from '../../assets/icons/ic-error.svg'
import fermer from '../../assets/icons/fermer.svg'

export default {
  name: 'Success',
  components: { PopupBase, buton },
  props: {
    activation: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        success,
        fermer
      },
      error: null,
      position: -1
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 45%;
    }
    .svg-icon{
      fill: transparent;
    }
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .titre {
      color: #ED2121;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
