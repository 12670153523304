<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/4 flex items-center">
        <icon
          :data="icons.entreprise"
          height="20"
          width="20"
          class="mr-2 cursor-pointer"
          original
        />
        <div>{{ item.company.fullName }}</div>
      </div>

      <div class="w-1/4 ml-4 flex items-center">
        {{ item.gerant.firstName }}
      </div>

      <div class="w-1/6 ml-4">
        {{ item.company.categorie }}
      </div>

      <div class="w-1/6 ml-4 flex items-center">
        {{ item.company.taille }}
      </div>

      <div class="w-1/6 ml-4 flex items-center">
        {{ new Date(item.participate.createdAt).toLocaleDateString() }}
      </div>
    </div>
  </div>
</template>

<script>
import client from '../../../assets/icons/ic-clientAv.svg'
import entreprise from '../../../assets/icons/ic-entreprise.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        client,
        entreprise
      },
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
