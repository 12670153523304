<template>
  <div class="p-6 pr-6 all">
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c22 items-center font-c8">
          <icon
            :data="icons.back"
            height="40"
            width="40"
            class="mr-4 cursor-pointer"
            original
            @click="goBack"
          />

          <div class="text-left text-c22 items-center font-c8">
            <span>{{ commercial.person.firstName }} {{ commercial.person.lastName }}</span>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <global-view
          :search="recherche"
          :filtrage="valueCategorie"
          @filt="filtrage = null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import search from '../../assets/icons/search.svg'
import entreprise from '../../assets/icons/ic-entreprise.svg'
import back from '../../assets/icons/backBut.svg'
import globalView from '../../component/cdevs/commercialDetail/globalData'

export default {
  name: "Index",

  components: {
    globalView,
  },

  data () {
    return {
      icons: {
        search,
        entreprise,
        back
      },
      recherche: null,
      valueCategorie: null,
      allCategorie: ['TOUT', 'BOUTIQUE', 'RESTAURATION'],
      commercial: null
    }
  },

  created () {
    this.commercial = this.$store.getters.trackCommercial
    // console.log(this.commercial)
  },

  methods: {
    retourSearch (answer) {
      this.recherche = answer
    },

    goBack () {
      window.history.back()
    },

    backFiltre(s) {
      if (s !== 'Catégorie'){
        this.valueCategorie = s
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
